import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const DialogosContext = createContext()

export const DialogosProvider = ({ children }) => {
  const [id, setId] = useState(null)
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [grades, setGrades] = useState([])
  const [competencies, setCompetencies] = useState([])
  const [active, setActive] = useState(true)
  const [isEditing, setIsEditing] = useState(false)

  // Lista de regionais, turmas e escolas que o estudante respondeu ao questionário
  const [disabledRegionalIds, setDisabledRegionalIds] = useState([])
  const [disabledSchoolIds, setDisabledSchoolIds] = useState([])
  const [disabledClassroomIds, setDisabledClassroomIds] = useState([])

  // Lista de regionais contendo escolas que o usuário selecionou na tela principal
  const [regionals, setRegionals] = useState([])

  // Informações que o usuário adicionou através do modal ModalDialogAddEdit
  const [schoolsPlus, setSchoolsPlus] = useState([])
  const [classroomsPlus, setClassroomsPlus] = useState([])

  // Informações que vem da avaliação já criada pelo usuário, usadas na edição
  const [existDialogRegionals, setExistDialogRegionals] = useState([])
  const [existDialogSchools, setExistDialogSchools] = useState([])
  const [existDialogClassrooms, setExistDialogClassrooms] = useState([])

  // Informações excluídas pelo usuário
  const [excludedRegionals, setExcludedRegionals] = useState([])
  const [excludedSchools, setExcludedSchools] = useState([])
  const [excludedClassrooms, setExcludedClassrooms] = useState([])

  const addExcludedRegional = regional => {
    setExcludedRegionals(prev => [...prev, regional])
  }

  const addExcludedSchool = school => {
    setExcludedSchools(prev => [...prev, school])
    setSchoolsPlus(prev => prev.filter(item => item.id !== school.id))
  }

  function removeExcludedSchool(school) {
    setExcludedSchools(prev => prev.filter(item => item.id !== school.id))
  }

  const addExcludedClassroom = classroom => {
    setExcludedClassrooms(prev => [...prev, classroom])
    setClassroomsPlus(prev => prev.filter(item => item.id !== classroom.id))
  }

  function removeExcludedClassroom(classroom) {
    setExcludedClassrooms(prev => prev.filter(item => item.id !== classroom.id))
  }

  const isExcluded = (type, idParam) => {
    switch (type) {
      case 'regional':
        return excludedRegionals.some(regional => regional.id === idParam)
      case 'school':
        return excludedSchools.some(school => school.id === idParam)
      case 'classroom':
        return excludedClassrooms.some(classroom => classroom.id === idParam)
      default:
        return false
    }
  }

  function removeExcludedRegional(regional) {
    setExcludedRegionals(prev => prev.filter(item => item.id !== regional.id))
  }

  const resetDialogosContext = () => {
    setId(null)
    setName(null)
    setDescription(null)
    setGrades([])
    setCompetencies([])
    setActive(true)
    setIsEditing(false)
    setRegionals([])
    setSchoolsPlus([])
    setClassroomsPlus([])
    setExistDialogRegionals([])
    setExistDialogSchools([])
    setExistDialogClassrooms([])
    setExcludedRegionals([])
    setExcludedSchools([])
    setExcludedClassrooms([])
    setExcludedRegionals([])
    setExcludedSchools([])
    setExcludedClassrooms([])
  }

  return (
    <DialogosContext.Provider
      value={{
        id,
        setId,
        name,
        setName,
        description,
        setDescription,
        grades,
        setGrades,
        competencies,
        setCompetencies,
        isEditing,
        setIsEditing,
        disabledRegionalIds,
        setDisabledRegionalIds,
        disabledSchoolIds,
        setDisabledSchoolIds,
        disabledClassroomIds,
        setDisabledClassroomIds,
        active,
        setActive,
        regionals,
        setRegionals,
        schoolsPlus,
        setSchoolsPlus,
        classroomsPlus,
        setClassroomsPlus,
        existDialogRegionals,
        setExistDialogRegionals,
        existDialogSchools,
        setExistDialogSchools,
        existDialogClassrooms,
        setExistDialogClassrooms,
        excludedRegionals,
        addExcludedRegional,
        excludedSchools,
        addExcludedSchool,
        excludedClassrooms,
        addExcludedClassroom,
        isExcluded,
        resetDialogosContext,
        removeExcludedSchool,
        removeExcludedClassroom,
        removeExcludedRegional,
      }}
    >
      {children}
    </DialogosContext.Provider>
  )
}

export default DialogosProvider

DialogosProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
