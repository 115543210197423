import { useContext, useCallback } from 'react'
import { SnackbarContext } from '../../contexts/SnackbarContext/SnackbarContext'

export function useSnackbar() {
  const { dispatch } = useContext(SnackbarContext)
  const variants = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
    custom: 'custom',
  }

  const showRequestError = useCallback(
    error => {
      let snackMessage = 'Ops! Sistema instável, tente novamente!'
      if (error && error.response && error.response.data) {
        const { message } = error.response.data
        snackMessage = message
      }
      dispatch({ type: 'show', payload: { message: snackMessage, variant: variants.error } })
    },
    [dispatch, variants.error],
  )

  const showRequestErrorDetail = useCallback(
    error => {
      let snackMessage = 'Ops! Sistema instável, tente novamente!'
      if (error?.response?.data?.detail) {
        const message = error.response.data.detail
        snackMessage = message
      }
      dispatch({ type: 'show', payload: { message: snackMessage, variant: variants.error } })
    },
    [dispatch, variants.error],
  )

  const showSuccess = useCallback(
    msg => {
      const message = msg || 'Dados salvos com sucesso!'
      dispatch({ type: 'show', payload: { message, variant: variants.success } })
    },
    [dispatch, variants.success],
  )

  function snackbarSuccess(message) {
    dispatch({ type: 'show', payload: { message, variant: variants.success } })
  }

  function snackbarSuccessAutoHide(message, secondsToHide) {
    dispatch({
      type: 'show',
      payload: { message, variant: variants.success, autoHideDuration: secondsToHide * 1000 },
    })
  }

  function snackbarInfo(message) {
    dispatch({ type: 'show', payload: { message, variant: variants.info } })
  }

  function snackbarWarning(message) {
    dispatch({ type: 'show', payload: { message, variant: variants.warning } })
  }

  function snackbarWarningAutoHide(message, secondsToHide) {
    dispatch({
      type: 'show',
      payload: { message, variant: variants.warning, autoHideDuration: secondsToHide * 1000 },
    })
  }

  function snackbarError(message) {
    dispatch({ type: 'show', payload: { message, variant: variants.error } })
  }

  function snackbarErrorAutoHide(message, secondsToHide) {
    dispatch({
      type: 'show',
      payload: { message, variant: variants.error, autoHideDuration: secondsToHide * 1000 },
    })
  }

  function snackbarErrorHelp(message) {
    dispatch({ type: 'show', payload: { message, variant: variants.error, buttonHelp: true } })
  }

  function hideSnackbar() {
    dispatch({ type: 'hide' })
  }

  function snackbarCustomAutoHide(message, secondsToHide, bgColor, textColor, icon) {
    dispatch({
      type: 'show',
      payload: {
        message,
        variant: variants.custom,
        autoHideDuration: secondsToHide * 1000,
        bgColor,
        textColor,
        icon,
      },
    })
  }

  return {
    snackbarSuccess,
    snackbarSuccessAutoHide,
    snackbarInfo,
    snackbarWarning,
    snackbarWarningAutoHide,
    snackbarError,
    snackbarErrorAutoHide,
    snackbarErrorHelp,
    hideSnackbar,
    showSuccess,
    showRequestError,
    showRequestErrorDetail,
    snackbarCustomAutoHide,
  }
}
