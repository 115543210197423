const getSelectedClassrooms = () => {
  return new Map(JSON.parse(localStorage.getItem('selectedClassrooms')))
}

const saveSelectedClassrooms = (selectedClassrooms = []) => {
  const selectedClassroomsMap = new Map()

  selectedClassrooms.forEach(selectedClassroom => {
    if (!selectedClassroomsMap.has(selectedClassroom.id)) {
      selectedClassroomsMap.set(selectedClassroom.id, selectedClassroom)
    }
  })

  const selectedClassroomsString = JSON.stringify(Array.from(selectedClassroomsMap.entries()))
  localStorage.setItem('selectedClassrooms', selectedClassroomsString)

  return selectedClassroomsMap
}

const compareObjectsWithoutYear = (obj1, obj2) => {
  const removeYear = obj => {
    const newObj = {}
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in obj) {
      const newKey = key.replace(/\d{4}$/, '')
      newObj[newKey] = obj[key]
    }
    return newObj
  }
  return JSON.stringify(removeYear(obj1)) === JSON.stringify(removeYear(obj2))
}
export { getSelectedClassrooms, saveSelectedClassrooms, compareObjectsWithoutYear }
