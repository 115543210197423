/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import React, { useState, createContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import api from '../../services/api'
import { useSnackbar } from '../../shared/hooks'
import { useLinkProgramsService } from '../../pages/ProgramList/hooks'

export const ClassroomContext = createContext({
  schoolYear: {},
  programType: '',
  listObject: [],
  textMeses: {},
  listSteps: [],
  textMaterial: [],
  progresso: 0,
  material: 0,
  indexList: 0,
  objectExcesso: [],
  classDaysOnly: false,
  objectExcessoToSchool: false,
  setProgramType: () => '',
  setObject: () => {},
  setListObjectClear: () => [],
  setObjectClear: () => {},
  setObjectExcessoClear: () => [],
  setListSteps: () => [],
  setListClassrooms: () => [],
  setListMultiplePrograms: () => [],
  setProgresso: () => 0,
  setMaterial: () => 0,
  setIndexList: () => 0,
  handleProgram: async () => 0,
  setHaveResponse: () => false,
  setClassDaysOnly: () => false,
  handleSaveListObjected: async () => null,
  getSchoolYear: async () => {},
})

export default function ClassroomProvider({ children }) {
  const { snackbarError, snackbarSuccess } = useSnackbar()
  const [programType, _setProgramType] = useState([])
  const [object, _setObject] = useState({})
  const [listObject, _setListObject] = useState([])
  const [listSteps, _setListSteps] = useState([])
  const [listMultiplePrograms, _setListMultiplePrograms] = useState([])
  const [progresso, _setProgresso] = useState(0)
  const [indexList, setIndexList] = useState(0)
  const [material, _setMaterial] = useState(0)
  const [textMaterial, setTextMaterial] = useState([])
  const [responseData, setResponseData] = useState()
  const [idsClassroom, setIdsClassroom] = useState([])
  const [checkResponse, setCheckResponse] = useState(false)
  const [haveResponse, _setHaveResponse] = useState(false)
  const [classDaysOnly, _setClassDaysOnly] = useState(false)
  const [objectExcesso, _setObjectExcesso] = useState([])
  const [insertOnlyMonthsResponse, setInsertOnlyMonthsResponse] = useState(false)
  const [objectExcessoToSchool, _setObjectExcessoToSchool] = useState(false)
  const { linkProgramsService } = useLinkProgramsService()

  const textMeses = {
    janeiro: '',
    fevereiro: '',
    marco: '',
    abril: '',
    maio: '',
    junho: '',
    julho: '',
    agosto: '',
    setembro: '',
    outubro: '',
    novembro: '',
    dezembro: '',
  }

  const extractMonths = jsonDataArray => {
    const mesesArray = []
    jsonDataArray.forEach(jsonData => {
      const meses = {}
      for (const key in jsonData) {
        if (key.toLowerCase().endsWith('classdays')) {
          meses[key] = jsonData[key]
        }
      }
      meses.programId = jsonData.programId
      mesesArray.push(meses)
    })
    return mesesArray
  }

  const updateClassDays99To00 = (list, isAdm = false) => {
    let conversionSuccess = false

    const updatedList = list.map(item => {
      Object.keys(item).forEach(key => {
        if (
          key.toLowerCase().endsWith('classdays') &&
          (isAdm || (!isAdm && typeof item[key] === 'number'))
        ) {
          if (item[key] === 99 || (isAdm && Number(item[key]) === 99)) {
            item[key] = '00'
            conversionSuccess = true
          } else {
            item[key] = String(item[key])
          }
        }
      })
      return item
    })

    return { updatedList, conversionSuccess }
  }

  const getSchoolYear = async classRoomId => {
    try {
      if (classRoomId !== null) {
        const response = await api.get(`classrooms/${classRoomId}`)
        if (response.data) {
          return response.data.schoolYear
        }
      } else {
        return { id: new Date().getFullYear(), name: new Date().getFullYear().toString() }
      }
    } catch (error) {
      snackbarError(error.response?.data?.detail)
    }
  }

  const handleProgram = async id => {
    const { data } = await api
      .get(`programCurricularComponent/program/${id}`)
      .catch(error => snackbarError(error.response?.data?.detail))

    const getTextMaterial = []
    for (const dados of data) {
      getTextMaterial.push({
        id: dados.curricularComponentId.id,
        name: dados.curricularComponentId.name,
      })
    }
    setTextMaterial(getTextMaterial)

    return getTextMaterial.length
  }

  const setHaveResponse = value => {
    _setHaveResponse(value)
  }

  const setObjectExcesso = value => {
    _setObjectExcesso(value)
  }

  const setObjectExcessoToSchool = value => {
    _setObjectExcessoToSchool(value)
  }

  const setClassDaysOnly = value => {
    _setClassDaysOnly(value)
  }

  const setProgramType = id => {
    _setProgramType(id)
    handleProgram(id)
  }

  const setListSteps = value => {
    _setListSteps(value)
  }
  const setListMultiplePrograms = value => {
    _setListMultiplePrograms(value)
  }

  const setObject = values => {
    _setObject(prevState => ({
      ...prevState,
      ...values,
    }))
  }

  const setListClassrooms = values => {
    setIdsClassroom(values)
  }

  const setObjectClear = () => {
    _setObject({})
  }

  const setObjectExcessoClear = () => {
    _setObjectExcesso([])
  }

  const setListObjectClear = () => {
    _setListObject([])
  }

  const setProgresso = value => {
    _setProgresso(value)
  }

  const fetchProgramCurricularExcess = useCallback(async objectParam => {
    try {
      const { data } = await api.get(
        `programCurricularExcess/classroomId/${objectParam.classRoomId}`,
      )
      if (data && Array.isArray(data)) {
        const activeItems = data.filter(item => item.active)
        if (activeItems.length > 0) {
          setObjectExcessoToSchool(false)
          setObjectExcesso(activeItems)
          return
        }
      }
    } catch (error) {
      snackbarError(error.response?.data?.detail)
    }

    try {
      const response = await api.get(
        `programCurricularExcess/allClassroomToSchool/${objectParam.classRoomId}`,
      )
      const { data } = response
      if (data && data.active) {
        setObjectExcessoToSchool(true)
        setObjectExcesso([data])
      }
    } catch (error) {
      snackbarError(error.response?.data?.detail)
    }
  }, [])

  const checkProgramCurricular = useCallback(async (classRoomId, programCurricularId) => {
    try {
      const { data } = await api.get(`programCurricular/${programCurricularId}`)
      if (data) {
        const { updatedList: dataUpdate } = updateClassDays99To00([data])
        let finalData = []
        if (classRoomId === dataUpdate.classRoomId) {
          finalData = dataUpdate
        } else {
          finalData = extractMonths(dataUpdate)
          setInsertOnlyMonthsResponse(true)
        }
        setResponseData(finalData)
      }
    } catch (error) {
      snackbarError(error.response?.dataUpdate?.detail)
    }
  }, [])

  const checkHaveResponse = useCallback(async (objectParam, programCurricularId) => {
    try {
      const params = {
        isView: objectParam.isViewOnlyModal,
      }
      const { data } = await api.get(`programCurricular/classRoom/${objectParam.classRoomId}`, {
        params,
      })
      const { updatedList: dataUpdate, conversionSuccess } = updateClassDays99To00(data)
      if (dataUpdate.length > 0 && objectParam.isView) {
        setResponseData(dataUpdate)
        setHaveResponse(true)
      } else if (conversionSuccess) {
        setResponseData(dataUpdate)
      } else if (programCurricularId) {
        await checkProgramCurricular(objectParam.classRoomId, programCurricularId)
      }
    } catch (error) {
      snackbarError(error.response?.dataUpdate?.detail)
    } finally {
      setCheckResponse(true)
    }
  }, [])

  useEffect(() => {
    if (object.classRoomId) {
      checkHaveResponse(object, objectExcesso[0]?.programCurricularId)
    } else {
      setCheckResponse(true) // Para o fluxo do ADM, onde ainda não existe classroomId
    }
  }, [object, objectExcesso])

  useEffect(() => {
    if (object.classRoomId) {
      fetchProgramCurricularExcess(object)
    }
  }, [object])

  const setMaterial = (index, meses, isGoToBack, finish) => {
    _setMaterial(index)
    if (isGoToBack) {
      if (!finish) {
        setIndexList(indexList > 0 && indexList - 1)
      }
    } else {
      if (indexList < listObject.length) {
        _setListObject(prevState => {
          const updatedList = [...prevState]
          updatedList[indexList] = {
            ...updatedList[index],
            ...{ ...object, ...meses, programId: object.programId },
          }
          return updatedList
        })
      } else {
        _setListObject(prevState => [
          ...prevState,
          { ...object, ...meses, programId: object.programId },
        ])
      }
      if (!finish) {
        setIndexList(indexList + 1)
      }
    }
  }
  function relacionarClassroomsComRequests(classroomIds, programCurricularRequests) {
    const programCurricularRequestsTratado = []

    classroomIds.forEach(classRoomId => {
      programCurricularRequests.forEach(request => {
        const novoRequest = { ...request, classRoomId }
        programCurricularRequestsTratado.push(novoRequest)
      })
    })

    return programCurricularRequestsTratado
  }

  function extractExcessClassDays(source) {
    const excessClassDays = []

    Object.keys(source).forEach(key => {
      const yearMatch = key.match(/(\d{4})$/)
      if (yearMatch) {
        const month = key.replace(yearMatch[0], '')
        const monthIndex = {
          januaryClassDays: 1,
          februaryClassDays: 2,
          marchClassDays: 3,
          aprilClassDays: 4,
          mayClassDays: 5,
          juneClassDays: 6,
          julyClassDays: 7,
          augustClassDays: 8,
          septemberClassDays: 9,
          octoberClassDays: 10,
          novemberClassDays: 11,
          decemberClassDays: 12,
        }[month]

        if (monthIndex) {
          const excessDay = {
            programFrequencyItemId: monthIndex.toString(),
            classDays: source[key].toString(),
          }
          excessClassDays.push(excessDay)
        }
      }
    })

    return excessClassDays
  }

  const transformExcessFields = (list, programCurriculars) => {
    return list.map((item, index) => {
      const excessYear = item.schoolYearDifferentiated.value

      const regularMonths = [
        item.januaryClassDays,
        item.februaryClassDays,
        item.marchClassDays,
        item.aprilClassDays,
        item.mayClassDays,
        item.juneClassDays,
        item.julyClassDays,
        item.augustClassDays,
        item.septemberClassDays,
        item.octoberClassDays,
        item.novemberClassDays,
        item.decemberClassDays,
      ]
      const zeroMonthCount = regularMonths.filter(month => month === '0').length

      const excessMonths = [
        { programFrequencyItemId: '1', classDays: item[`januaryClassDays${excessYear}`] },
        { programFrequencyItemId: '2', classDays: item[`februaryClassDays${excessYear}`] },
        { programFrequencyItemId: '3', classDays: item[`marchClassDays${excessYear}`] },
        { programFrequencyItemId: '4', classDays: item[`aprilClassDays${excessYear}`] },
        { programFrequencyItemId: '5', classDays: item[`mayClassDays${excessYear}`] },
        { programFrequencyItemId: '6', classDays: item[`juneClassDays${excessYear}`] },
        { programFrequencyItemId: '7', classDays: item[`julyClassDays${excessYear}`] },
        { programFrequencyItemId: '8', classDays: item[`augustClassDays${excessYear}`] },
        { programFrequencyItemId: '9', classDays: item[`septemberClassDays${excessYear}`] },
        { programFrequencyItemId: '10', classDays: item[`octoberClassDays${excessYear}`] },
        { programFrequencyItemId: '11', classDays: item[`novemberClassDays${excessYear}`] },
        { programFrequencyItemId: '12', classDays: item[`decemberClassDays${excessYear}`] },
      ]

      const filteredExcessMonths = excessMonths
        .filter(month => month.classDays !== undefined && month.classDays !== null)
        .slice(0, zeroMonthCount)

      return {
        schoolYear: item.schoolYearDifferentiated.name,
        excessClassDays: filteredExcessMonths,
        active: item?.calendar?.value === 'CALENDARIO_DIFERENCIADO',
        programCurricularId: programCurriculars?.id || programCurriculars[index]?.id,
      }
    })
  }

  function transformExcessFieldsSaveAll(
    classroomIds,
    programCurricularRequests,
    programCurriculars,
  ) {
    const results = []

    const programCurricularRequestsForClassRoom = relacionarClassroomsComRequests(
      classroomIds,
      programCurricularRequests,
    )

    classroomIds.forEach(classRoomId => {
      const requestsForClassRoom = programCurricularRequestsForClassRoom.filter(
        request => request.classRoomId === classRoomId,
      )
      const programCurricularsForClassRoom = programCurriculars.filter(
        pc => pc.classRoomId === classRoomId,
      )

      requestsForClassRoom.forEach((request, index) => {
        const excessClassDays = extractExcessClassDays(request)

        const programCurricular = programCurricularsForClassRoom[index]
        const programCurricularId = programCurricular ? programCurricular.id : null

        results.push({
          schoolYear: request.schoolYearDifferentiated.name,
          excessClassDays,
          active: 'true',
          programCurricularId,
        })
      })
    })
    return results
  }

  const handleSaveListObjectedExcess = async list => {
    if (list.length > 0) {
      try {
        const response = await api.post(`programCurricularExcess`, list)
        if (response.status === 200) {
          return true
        }
      } catch (error) {
        snackbarError(error.response?.data?.detail)
        return false
      }
    }
  }

  const handleLinkProgramsService = async isView => {
    if (isView) {
      return true
    }

    try {
      return await linkProgramsService(
        Array.isArray(listMultiplePrograms) ? listMultiplePrograms : [listMultiplePrograms],
      )
    } catch (error) {
      snackbarError(error.response?.data?.detail)
      return false
    }
  }

  const handlePostRequest = async (list, hasYear) => {
    try {
      const response = await api.post(`programCurricular/saveAll`, list)
      if (response.status === 200 && hasYear) {
        const transformedList = transformExcessFieldsSaveAll(
          list.classroomIds,
          list.programCurricularRequests,
          response.data,
        )

        if (
          ((isEmpty(objectExcesso) || insertOnlyMonthsResponse) &&
            list.programCurricularRequests[0]?.calendar?.value === 'CALENDARIO_DIFERENCIADO') ||
          (!haveResponse &&
            list.programCurricularRequests[0]?.calendar?.value === 'CALENDARIO_DIFERENCIADO')
        ) {
          await handleSaveListObjectedExcess(transformedList)
        }
      }
      snackbarSuccess('Dados inseridos com sucesso!')
      return true
    } catch (error) {
      snackbarError(error.response?.data?.detail)
      return false
    }
  }

  const handlePutRequest = async (listObjectList, hasYear) => {
    try {
      const response = await api.put('programCurricular/', listObjectList, {
        params: {
          classDaysOnly,
        },
      })

      if (response.status === 200 && hasYear) {
        const transformedList = transformExcessFields(listObjectList, response.data)
        if (!classDaysOnly) {
          await api.put('programCurricularExcess/update-full', transformedList, {
            params: {
              reactivate: listObjectList[0]?.calendar?.value === 'CALENDARIO_DIFERENCIADO',
            },
          })
        } else {
          await api.put('programCurricularExcess/update-classdays-only', transformedList)
        }
      }
      snackbarSuccess('Edição realizada com sucesso!')
      return true
    } catch (error) {
      snackbarError(error.response?.data?.detail)
      return false
    }
  }

  const updateClassDays00To99 = list => {
    return list.map(item => {
      Object.keys(item).forEach(key => {
        if (key.toLowerCase().endsWith('classdays') && item[key] === '00') {
          item[key] = '99'
        }
      })
      return item
    })
  }

  const handleSaveListObjected = async (isView, isAdm) => {
    const listObjectUpdate = updateClassDays00To99(listObject)
    const list = {
      programCurricularRequests: listObjectUpdate,
      classroomIds: idsClassroom,
    }

    if (haveResponse) {
      listObjectUpdate.forEach(obj => {
        if (
          typeof obj.excessJustifications === 'string' &&
          obj.excessJustifications.trim() !== ''
        ) {
          obj.excessJustifications = [
            {
              programCurricularId: obj.id,
              description: obj.excessJustifications.trim(),
            },
          ]
        } else {
          obj.excessJustifications = []
        }
      })
    }

    let hasYear = false
    listObjectUpdate.forEach(item => {
      Object.keys(item).forEach(key => {
        const yearMatch = key.match(/(\d{4})$/)
        if (yearMatch) {
          hasYear = true
          return hasYear
        }
      })
    })

    if (!isAdm) {
      // Aqui é o fluxo normal que já temos hoje
      const responsePrograms = await handleLinkProgramsService(isView, isAdm)
      if (!isEmpty(responsePrograms) && (!haveResponse || insertOnlyMonthsResponse)) {
        const success = await handlePostRequest(list, hasYear)
        return success
      }

      if (haveResponse) {
        const success = await handlePutRequest(listObjectUpdate, hasYear)
        return success
      }
    } else {
      // Aqui é o fluxo específico do ADM, retornando o objeto para ser tratado pelo handleSave que vem do componente do ADM

      // eslint-disable-next-line array-callback-return
      const excessList = list.programCurricularRequests.map((l, index) => {
        const excesses = extractExcessClassDays(l)
        if (excesses.length > 0 && l.calendar.value === 'CALENDARIO_DIFERENCIADO') {
          return {
            schoolYear: l.schoolYearDifferentiated.name,
            excessClassDays: excesses,
            active: 'true',
            programCurricularIndex: index,
          }
        }

        return ''
      })

      const finalExcessList = excessList.filter(ex => ex !== '')

      return { list, listObjectUpdate, finalExcessList }
    }
  }

  return (
    <ClassroomContext.Provider
      value={{
        programType,
        listObject,
        textMeses,
        listSteps,
        textMaterial,
        progresso,
        material,
        indexList,
        checkResponse,
        haveResponse,
        responseData,
        objectExcesso,
        classDaysOnly,
        objectExcessoToSchool,
        setCheckResponse,
        setHaveResponse,
        setResponseData,
        setProgramType,
        setObject,
        setProgresso,
        setMaterial,
        setListSteps,
        handleSaveListObjected,
        setObjectClear,
        setObjectExcessoClear,
        setListObjectClear,
        setIndexList,
        setListClassrooms,
        setListMultiplePrograms,
        handleProgram,
        setClassDaysOnly,
        getSchoolYear,
        updateClassDays99To00,
        updateClassDays00To99,
      }}
    >
      {children}
    </ClassroomContext.Provider>
  )
}

ClassroomProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
